import { app } from "../../constants/common";

const english = {
  landing: {
    welcome: `Welcome to ${app.groupName.en}  payment submission`,
    readInfo: "Read the information below carefully.",
    howItWorks: "HOW IT WORKS",
    step01: {
      title: "STEP 01",
      headline: "HAVE YOUR CUSTOMER CODE READY",
      content:
        "You will need your customer code to continue with payment submission. Make sure you see and confirm your Customer Name, Depot and Registration number",
    },
    step02: {
      title: "STEP 02",
      headline: "CONFIRM PAYMENT AMOUNT AND SELECT PAYMENT METHOD",
      content:
        "You will get the chance to enter your amount and also select the payment method. You will also make sure the right receiver is selected from the drop down",
    },
    step03: {
      title: "STEP 03",
      headline: "CONFIRM AND MAKE PAYMENTS USING YOUR PAYMENT METHOD",
      content:
        "You will get a request to confirm payment on your mobile phone number. Make sure the payment is completed on the mobile device to continue",
    },
    skip: "SKIP",
    allRightsReserved: "© 2021 All rights reserved.",
  },
  termsAndConditions: {
    tabTitle: "Disclaimer",
    beforeYouContinue: "Before you continue, read the disclaimer",
    pleaseRead1: "Please read and confirm the ",
    pleaseRead2: "terms and conditions",
    pleaseRead3: " of use",
    iHave: "I have read and understood the terms and conditions",
    buttonAccept: "Accept terms and conditions",
    modal: {
      title: "Terms and Conditions",
      paragraph1:
        "Terms of service (also known as terms of use and terms and conditions, commonly abbreviated as TOS or ToS, ToU or T&C) are the legal agreements between a service provider and a person who wants to use that service. The person must agree to abide by the terms of service in order to use the offered service.",
      paragraph2:
        "Terms of service can also be merely a disclaimer, especially regarding the use of websites. Vague language and lengthy sentences used in the terms of use have brought concerns on customer privacy and raised public awareness in many ways.  The Terms of Service Agreement is mainly used for legal purposes by companies which provide software or services, such as web browsers, e-commerce, web search engines, social media, and transport services.  A legitimate terms-of-service agreement is legally binding and may be subject to change.",
      paragraph3:
        "Companies can enforce the terms by refusing service. Customers can enforce by filing a lawsuit or arbitration case if they can show they were actually harmed by a breach of the terms. There is a heightened risk of data going astray during corporate changes, including mergers, divestitures, buyouts, downsizing, etc., when data can be transferred improperly.",
      buttonClose: "CLOSE",
      allRightsReserved: "© 2021 All rights reserved.",
    },
    errorRecaptcha: "reCAPTCHA verification failed!",
  },
  search: {
    tabTitle: "Search",
    stepBlock: {
      title: "Step 01",
      headline: "Enter your customer code and search",
      firstBullet: "This is a unique code from SABC",
      secondBullet: "Make sure you enter it exactly as given to you",
      thirdBullet: "If the search results are incorrect. Contact SABC",
    },
    panel: {
      enterYour: "Enter Customer Code: ",
      buttonSearch: "SEARCH",
      searchResultsBelow: "Search results below",
      customerName: "Customer Name",
      customerDepot: "Customer Depot",
      registrationNumber: "Registration No.",
      company: "Company",
      error: "ERROR: ",
      errorMessageNoValue: "NO CUSTOMER CODE PROVIDED",
      errorMessageInvalidCustomerCode: "CUSTOMER CODE FORMAT IS INVALID",
      errorRecaptcha: "reCAPTCHA verification failed!",
      continueToPayment: "CONTINUE TO PAYMENT",
    },
  },
  payment: {
    tabTitle: "Payment",
    stepBlock: {
      title: "Step 02",
      headline: "Enter your payment details",
      firstBullet: "Enter the amount",
      secondBullet: "Confirm the depot receiver is correct",
      thirdBullet: "Select your payment method",
    },
    panel: {
      yourCustomerCode: "Your Customer Code:",
      customerName: "Customer Name:",
      enterAmount: "Enter Amount",
      phoneNumber: "Enter your Phone Number",
      selectPaymentMethod: "Select Payment Method",
      proceed: "Proceed",
      error: "ERROR: ",
      errorMessagePaymentRequired: "PAYMENT AMOUNT IS REQUIRED",
      errorMessagePhoneRequired: "PHONE NUMBER IS REQUIRED",
      errorMessagePhoneInvalid: "PHONE NUMBER IS INVALID",
      errorMessagePhoneInvalidProvider:
        "PHONE NUMBER IS INVALID FOR THIS PROVIDER",
      errorMessageMissingData:
        "There are some data missing! Please go back and try again. If the issue continues to persist, please contact support.",
    },
  },
  paymentConfirm: {
    instruction: {
      mtn: "Dial *126# to validate your withdrawal in case you did not receive prompt.",
      orange:
        "Dial #150*50# to validate your withdrawal  in case you did not receive prompt.",
      yup: "",
    },
    tabTitle: "Payment Confirm",
    stepBlock: {
      title: "Step 03",
      headline: "Enter your payment details",
      firstBullet: "Check your Customer Code",
      secondBullet: "Check your amount",
      thirdBullet: "Check your phone number",
    },
    panel: {
      instruction: "Instruction",
      title: "Payment Confirmation",
      yourCustomerCode: "Your Customer Code:",
      customerName: "Customer Name:",
      paymentAmount: "Payment Amount:",
      customerDepot: "Customer Depot",
      paymentMethod: "Payment Method:",
      phoneNumber: "Phone Number:",
      termsAndConditions1: "Terms and Conditions:",
      termsAndConditionsAccepted: " ACCEPTED ",
      termsAndConditionsRefused: " REFUSED ",
      buttonConfirm: "Confirm Payment",
      error: "ERROR: ",
      errorMessageMissingData:
        "There are some data missing! Please go back and try again. If the issue continues to persist, please contact support.",
    },
  },
  paymentSuccess: {
    tabTitle: "Payment Success",
    panel: {
      title: "Payment Successful!",
      yourCustomerCode: "Your Customer Code:",
      customerName: "Customer Name:",
      paymentAmount: "Payment Amount:",
      customerDepot: "Customer Depot:",
      paymentMethod: "Payment Method:",
      phoneNumber: "Phone Number:",
      paymentsConfirmed: "Date of Payment:",
      shareReceipts: "Share Receipts",
      processingPayment: "Processing the payment...",
      buttonMakeAnotherPayment: "Make another payment",
      error: "ERROR: ",
      errorPaymentFailed:
        "The system failed to process the payment. Please contact us.",
      errorMessageMissingData:
        "There are some data missing! Please go back and try again. If the issue continues to persist, please contact support.",
    },
  },
  serverErrorMessages: {
    40002: "Access key is invalid (unauthorized X-API-KEY)",
    40003: "Agent Access key is invalid (agent not configured in API)",
    40010: "Request is missing mandatory fields",
    40301: "Initiation could not be completed as destination is invalid",
    40302: "Input data is invalid",
    40602: "Service ID is invalid",
    40604: "Payment item not found",
    40605: "Service is not supported",
    40607: "Payment method is invalid or not active",
    40610: "Payment error Could not create quote",

    40409:
      "Please enter valid amount as entered amount is too small or too high. ({{originalErrorCode}})",
    41004:
      "The operator is currently unavailable. Please try again later or contact support.",
    41003:
      "The operator is currently unavailable. Please try again later or contact support.",
    40702:
      "Duplicate payment prevented! Please retry after 5 minutes or change the payment amount.",
    40617: "No payment found for the provided details. ({{originalErrorCode}})",
    50000: "Transaction failed! Contact Support. ({{originalErrorCode}})",
    50001: "Configuration error on server",
    703108: "You don't have enough funds to perform this transaction.",
    703111: "You have exceeded your daily/weekly/monthly Limit",
    703112: "You have exceeded your daily/weekly/monthly Limit",
    703117: "Phone number entered is not supported by the service provider!",
    703201: "You did not validate payment with your PIN code.",
    703202: "You rejected this payment.",
    703203: "You have entered a wrong PIN code.",
    703104: "Your account is blocked. Contact your service provider.",
    703106:
      "ERROR: Service provider does not know the Sender/Initiator Account of the transaction (703106).",
  },
};

export default english;
